<template>
  <dialogDiyNew
    :isShow="isShow"
    :dialogDiyData="dialogDiyData"
    @dialogEvent="dialogDiyEvent"
  >
    <div slot="dialogContent" class="exam_area">
      <slot name="extendHead"></slot>
      <el-transfer
        filterable
        :titles="titles"
        :filter-placeholder="
          diyData.serchName
            ? diyData.serchName
            : '请输入课程名称'
        "
        v-model="value"
        :data="data"
        :props="propsData"
        @change="changeTran"
        @left-check-change="changeLeft"
      >
      </el-transfer>
      <div class="exam_btn" v-if="isDefaultBtn">
        <el-button type="primary" @click="enterEvent">确 定</el-button>
        <el-button @click="dialogDiyEvent">取 消</el-button>
      </div>
      <template v-else>
        <slot name="customizeBtn"></slot>
      </template>
    </div>
  </dialogDiyNew>
</template>

<script>
import dialogDiyNew from "../dialogDiyNew"
export default {
  name: "examTransfer",
  props: {
    isDiyTransfer: {
      type: Boolean,
      default: false
    },
    diyData: {
      type: Object,
      default: () => {}
    },
    isDefaultBtn: {
      type: Boolean,
      default: true
    }
  },
  components: {
    dialogDiyNew,
  },
  data() {
    return {
      dialogDiyData: {},
      titles: [],
      data: [],
      listData: [],
      majorNameData: [],
      value: [],
      propsData: {key: 'key', label: 'label'},
      isFilterable: true,
      searchNum: 0,
      queryName: null,
      isFlag: false,
      temporaryStorage: []
    };
  },
  watch: {
    diyData: {
      deep: true,
      handler(newVal, oldVal) {
        if (!this.$lodash.isEmpty(newVal)) {
          console.log('object',newVal);
          console.log(this.value,66)
          let diyData = this.$lodash.cloneDeep(newVal)
          this.dialogDiyData = {
            title: diyData.dialogDiytitle,
            sizeStyle: diyData.sizeStyle,
          };
          this.titles = diyData.title;
          this.data = diyData.transferData;
          // this.value = this.value.length != 0 ? this.value : diyData.ids;
          this.value = diyData.ids;
          // this.temporaryStorage = this.temporaryStorage.length != 0 ? this.temporaryStorage : diyData.ids;
          this.propsData = diyData.propsData ? diyData.propsData : this.propsData
          console.log(this.value,77)
        }
      },
    },
  },
  computed: {
    isShow() {
      return this.isDiyTransfer
    }
  },
  methods: {
    dialogDiyEvent(val) {
      // this.value = this.temporaryStorage
      this.$emit("showTransfer");
    },
    enterEvent() {
      let obj = {
        ids: this.value,
      }
      this.$emit("showTransfer", obj)
    },
    changeTran(e) {
      this.$emit('change', e, this.value)
    },
    changeLeft(e) {
      this.$emit('changeLeft', e, this.value)
    }
  },
};
</script>

<style lang="less" scoped>
.exam_area {
  box-sizing: border-box;
  padding: 20px;
  /deep/ .el-transfer {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-transfer-panel {
      flex: 1;
    }
    .el-transfer-panel__body {
      height: 400px;
      .el-checkbox-group {
        height: calc(100% - 47px);
        // overflow-x: hidden;
      }
    }
  }
  .exam_btn {
    width: 100%;
    margin-top: 20px;
    margin-right: 30px;
    text-align: center;
  }
}
</style>
