import { render, staticRenderFns } from "./examTransferDiy.vue?vue&type=template&id=626cc758&scoped=true"
import script from "./examTransferDiy.vue?vue&type=script&lang=js"
export * from "./examTransferDiy.vue?vue&type=script&lang=js"
import style0 from "./examTransferDiy.vue?vue&type=style&index=0&id=626cc758&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626cc758",
  null
  
)

export default component.exports