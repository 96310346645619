<template>
  <div class="manageCusService">
    <el-form
      :model="formInline"
      class="demo-form-inline"
      label-width="130px"
    >
      <el-form-item>
        <template slot="label">
          <span class="red-pointed">*</span>
          <span>管理省份</span>
        </template>
        <el-select
          v-model="formInline.provinceId"
          placeholder="管理省份"
          filterable
          @change="changePro"
          :disabled="isEditProvince"
        >
          <el-option
            v-for="item in provinceData"
            :key="item.provinceId"
            :label="item.provinceName"
            :value="item.provinceId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客服形式">
        <el-radio-group v-model="formInline.type" @change="changeType">
          <el-radio v-for="(item, key) in $common.cusTypeData" :key="key" :label="Number(key)">{{item}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="formInline.type == 2">
        <template slot="label">
          <span class="red-pointed">*</span>
          <span>客服组名称</span>
        </template>
        <el-input
          v-model="formInline.name"
          placeholder="客服组名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <span class="red-pointed">*</span>
          <span>客服成员</span>
        </template>
        <el-select v-model="userId" placeholder="客服成员" clearable filterable v-if="formInline.type == 1" @change="changeUser">
          <el-option
            v-for="item in diyData.transferData"
            :key="item.userId"
            :label="`${item.realName}(${item.userName})`"
            :value="String(item.userId)">
          </el-option>
        </el-select>
        <el-button
          class="mainBtn"
          @click="changeCus"
          plain
          v-else
          >选择客服</el-button
        >
      </el-form-item>
      <el-form-item label="企业微信客服链接">
        <el-input
          v-model="formInline.enterpriseWechatUrl"
          placeholder="企业微信客服链接"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn_area">
      <el-button @click="cancelEvent">取消</el-button>
      <el-button type="primary" @click="enterEvent">确认</el-button>
    </div>

    <examTransferDiy
      :isDiyTransfer="isDiyTransfer"
      :diyData="diyData"
      @change="changeEvent"
      @changeLeft="changeLeftEvent"
      @showTransfer="showTransferEvent"
    >
      <template #extendHead>
        <div class="search_sList">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="cusForm"
              class="cus-form-inline"
              label-width="100px"
            >
              <el-form-item label="角色名称">
                <el-select v-model="cusForm.roleId" placeholder="角色名称" filterable clearable>
                  <el-option
                    v-for="item in roleNameData"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
      </template>
    </examTransferDiy>
  </div>
</template>

<script>
import examTransferDiy from 'components/commonModule/administration_module/examTransferDiy'
export default {
  name: 'manageCusService',
  components: {
    examTransferDiy
  },
  data () {
    return {
      formInline: {
        enterpriseWechatUrl: null,
        id: null,
        name: null,
        provinceId: null,
        provinceName: null,
        type: 1,
        userIdList: [],
        userNameList: [],
      },
      provinceData: [],
      diyData: {
        title: ["可选人员", "已选人员"],
        ids: [],
        serchName: '请输入姓名',
        transferData: [],
        dialogDiytitle: "选择客服",
        sizeStyle: { width: "650px", height: "730px" },
        propsData: {
          key: 'userId',
          label: 'realName'
        }
      },
      isDiyTransfer: false,
      cusForm: {
        roleId: null
      },
      objData: {},
      roleNameData: [],
      userId: null,
      isEditProvince: false,
    }
  },
  methods: {
    onSubmit() {
      this.getCustomerServiceObjList();
    },
    // 重置
    resetEvent() {
      this.cusForm.roleId = null
      this.getCustomerServiceObjList();
    },
    sendData(obj) {
      let { row, provinceData, type } = obj
      this.objData = obj
      this.provinceData = provinceData
      this.formInline = type == 'edit' ? Object.assign(this.formInline, row) : this.formInline
      if (type == 'edit') {
        this.formInline.userIdList = row.userIdsStr.split(',')
        this.formInline.userNameList = row.userNames.split(',')
        if (row.type == 1)
          this.userId = this.formInline.userIdList[0]
        else
          this.diyData.ids = this.formInline.userIdList.map(item => Number(item))
        this.getCustomerServiceObjList()
      }
      this.isEditProvince = (type == 'edit' && row.commercialSchoolCount) || (type == 'edit' && row.showSchoolCount) ? true : false
      this.getRoleDownEvent()
    },
    changeCus() {
      if(this.formInline.provinceId)
        this.showTransferEvent()
      else {
        this.Warn('请先选择管理省份，再进行选择客服')
      }
    },
    showTransferEvent(val = {}) {
      this.isDiyTransfer = !this.isDiyTransfer
      if (val.hasOwnProperty("ids")) {
        this.diyData.ids = val.ids
        this.$set(this.formInline, 'userIdList', val.ids)
        this.$set(this.formInline, 'userNameList', [])
        val.ids.forEach(item => {
          this.diyData.transferData.forEach(ele => {
            if(item == ele.userId) this.formInline.userNameList.push(ele.realName)
          })
        })
      }
      if (!this.isDiyTransfer) {
        this.resetEvent()
      }
    },
    cancelEvent() {
      this.$emit('dialogManEvent')
    },
    enterEvent() {
      let obj = this.$lodash.cloneDeep(this.formInline)
      // obj.userIdList = this.diyData.ids
      // obj.userNameList = []
      
      if (!obj.provinceId) {
        this.Warn('请选择管理省份')
        return
      }
      if (!obj.name && obj.type == 2) {
        this.Warn('请输入客服组名称')
        return
      }
      if (obj.userIdList.length == 0) {
        this.Warn('请选择客服成员')
        return
      }
      if(obj.type == 1) obj.name = obj.userNameList[0]
      let requestName = this.objData.type == 'add' ? 'addCustomerService' : 'updateCustomerService'
      this.$request[requestName](obj).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.$emit('getTableData')
          this.$emit('dialogManEvent')
        }
      })
    },
    changePro() {
      this.formInline.provinceName = this.provinceData.filter(item => item.provinceId == this.formInline.provinceId)[0].provinceName
      this.getCustomerServiceObjList()
    },
    // 获取管理成员
    getCustomerServiceObjList() {
      let obj = {
        roleId: this.cusForm.roleId,
        provinceId: this.formInline.provinceId
      }
      this.$request.customerServiceObjList(obj).then(res => {
        if (res.data.code == 0) {
          this.$set(this.diyData, 'transferData', res.data.data)
        }
      })
    },
    // 获取角色名称数据
    getRoleDownEvent() {
      this.$request.getRoleDown({}, res=>{
        if(res.code == 0) {
          this.roleNameData = res.data;
        }
      })
    },
    changeType() {
      this.userId = null
      this.formInline.userIdList = []
      this.formInline.userNameList = []
      this.$set(this.diyData, 'ids', [])
    },
    changeUser() {
      let uNameArr = this.diyData.transferData.filter(item => item.userId == this.userId)
      this.formInline.userIdList = [this.userId]
      this.formInline.userNameList = [uNameArr[0].realName]
    },
    changeEvent(e, val) {
      // if (this.formInline.type == 2) return;
      // if (e.length == 0) {
      //   this.diyData.transferData.forEach(item => item.disabled = false)
      //   return;
      // }
      // this.diyData.transferData.forEach(item => {
      //   if(e[0] != item.userId) item.disabled = true
      // })
    },
    changeLeftEvent(e, val) {
      // if (this.formInline.type == 2) return;
      // if (e.length > 1) {
      //   this.Warn('当前只能单选，不能全选')
      //   this.$set(this.diyData, 'ids', [])
      //   return
      // }
      // if (e.length == 0 && val.length == 0) {
      //   this.diyData.transferData.forEach(item => item.disabled = false)
      //   return;
      // }
      // this.diyData.transferData.forEach(item => {
      //   if(e[0] != item.userId) item.disabled = true
      // })
    }
  }
}
</script>

<style lang="less" scoped>
  .manageCusService {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    .btn_area {
      text-align: center;
    }
    .cus-form-inline .el-form-item {
      margin-bottom: 0;
    }
  }
</style>